import React from 'react'
import { motion } from 'framer-motion'
import moment from 'moment'

const AgendaDatesTab = ({ 
    agendaDates, 
    setSelectedTrackDate, 
    selectedTrackDate, 
    selectedDateBackground,
    otherTextColor,
    background 
    }) => {

    const isSelected = (date) => date === selectedTrackDate ? true : false

    const isSelectedFunc = (date, type) => { 
        if(type === "bg") {
            if(date === selectedTrackDate) return selectedDateBackground
            else return background
        }
        if(type === "color") {
            if (date === selectedTrackDate) return "white"
            else return otherTextColor
        }
    }

    return (
        <div className='d-flex overflow-auto py-2 px-2'>
            {agendaDates.map((date, i) => (
                <motion.button
                    className='date-btn text-start p-2 me-2'
                    style={{minWidth: "100px", background: isSelectedFunc(date, "bg"), color: isSelectedFunc(date, "color"), border: "1px solid #CACACA", borderRadius: "6px"}}
                    whileHover={{scale: !isSelected(date) ? 1.1 : 1}}
                    onClick={() => setSelectedTrackDate(date)}
                    key={`date-${i}`}
                >
                    <div className='day text-start'>
                    {moment(date).format("dddd")}
                    </div>
                    <div className='day-container'>
                        <span className='day-no'>{moment(date).format("D")}</span>
                        <span className='month ms-1'> {moment(date).format("MMM")}</span>
                    </div>
                    <style jsx>{`
                        .month{
                            text-transform: uppercase;
                            font-size: 20px;
                        }
                        .day {
                            font-size: 16px;;
                        }
                        .day-no {
                            font-size: 27px;
                            font-weight: 700;
                        }
                        .date-btn {
                            width: 70px;
                            border: 1px solid #CACACA;
                            border-radius: 6px;
                        }
                        .day-container {
                            margin-top: -6px;
                        }
                    `}</style>
                </motion.button>
            ))}
        </div>
    )
}

export default AgendaDatesTab
